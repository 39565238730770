/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/

import {validateAndSetValid, validateAndGenerateError} from "@/store/validationFromStore";
import { msToyyyyMMdd } from "@/app/utils/common/functions/functions";
import {copyFromApiResponse, copyFromErrorResponse} from "@/store/storeMappers";
import countrySpecificBusinessStructure
    from "@/store/modules/onboarding/conditionalApiFields/countrySpecificBusinessStructure";

export const businessStructureDefaultState = {
    api: "createMerchant",
    errors: {},
    data: {
        typeOfBusiness: {
            apiPath: "business_info.type",
            value: null,
            valid: false
        },
        ownershipType: {
            apiPath: "business_info.ownership_type",
            value: null,
            valid: false
        },
        incorporationDate: {
            apiPath: "business_info.incorporation_date",
            value: null,
            valid: false
        },
        merchantCategoryCode: {
            apiPath: "business_info.mcc",
            value: null,
            valid: false
        },
        ...countrySpecificBusinessStructure.apiFields
    }
};

const getters = {
    isValid: (state) => Object.keys(state.data).every(key => state.data[key].valid),
    getTypeOfBusiness: (s) => s.data.typeOfBusiness.value,
    getOwnershipType: (s) => s.data.ownershipType.value,
    getIncorporationDate: (s) => s.data.incorporationDate.value,
    getMerchantCategoryCode: (s) => s.data.merchantCategoryCode.value,
    getBusinessTypeError: s => s.errors.typeOfBusiness,
    getErrors: s => s.errors,
    ...countrySpecificBusinessStructure.getters,
    getCountrySpecificApiFields: () => countrySpecificBusinessStructure.apiFields,
}

const actions = {
    validate({commit, state, rootGetters}) {
        commit('resetErrors')
        return validateAndGenerateError(commit, state.api, rootGetters["businessAddress/getCountry"], state.data)
    },
    validateField({commit, state, rootGetters}, key) {
        validateAndSetValid(commit, businessStructureDefaultState.api, state.data, rootGetters["businessAddress/getCountry"], key)
    },
    validateAllFields({state, dispatch}) {
        Object.keys(state.data).forEach(key => dispatch('validateField', key))
    },
    resetErrors({commit}) {
        commit('resetErrors');
    },
    setErrors({commit}, {key, errors}) {
        commit('setErrors', {key, errors});
    },
    updateTypeOfBusiness({commit, dispatch}, value) {
        commit('updateTypeOfBusiness', value);
        dispatch('validateField', 'typeOfBusiness')
    },
    updateOwnershipType({commit, dispatch}, value) {
        commit('updateOwnershipType', value);
        dispatch('validateField', 'ownershipType')
    },
    updateIncorporationDate({commit, dispatch}, value) {
        commit('updateIncorporationDate', msToyyyyMMdd(value));
        dispatch('validateField', 'incorporationDate')
    },
    updateMerchantCategoryCode({commit, dispatch}, value) {
        commit('updateMerchantCategoryCode', value);
        dispatch('validateField', 'merchantCategoryCode')
    },
    updateCountrySpecificField({commit, dispatch}, {field, event}) {
        commit('updateCountrySpecificField', {field, value: event?.target?.value || event});
        dispatch('validateField', field)
    },
    copyFromFieldErrorsResponse({commit}, subErrors) {
        commit('copyFromFieldErrorsResponse', subErrors)
    },
    copyFromMerchantApiResponse({commit}, merchantApiResponse) {
        commit('copyFromMerchantApiResponse', merchantApiResponse)
    },

};

const mutations = {
    resetErrors(state): void {
        state.errors = {}
    },
    setInvalid(state, key) {
        state.data[key].valid = false;
    },
    setValid(state, key) {
        state.data[key].valid = true;
    },
    setErrors(state, {key, errors}): void {
        state.errors[key] = errors;
    },
    updateTypeOfBusiness(state, typeOfBusiness: string): void {
        state.data.typeOfBusiness.value = typeOfBusiness;
        delete state.errors.typeOfBusiness;
    },
    updateOwnershipType(state, ownershipType: string): void {
        state.data.ownershipType.value = ownershipType;
        delete state.errors.ownershipType;
    },
    updateIncorporationDate(state, incorporationDate: string): void {
        state.data.incorporationDate.value = incorporationDate;
        delete state.errors.incorporationDate;
    },
    updateMerchantCategoryCode(state, merchantCategoryCode: string): void {
        state.data.merchantCategoryCode.value = merchantCategoryCode;
        delete state.errors.merchantCategoryCode;
    },
    updateCountrySpecificField(state, {field, value}): void {
      state.data[field].value = value;
      delete state.errors[field];
    },
    copyFromFieldErrorsResponse: function (state, subErrors): void {
        state.errors = copyFromErrorResponse(subErrors, state.data)
    },
    copyFromMerchantApiResponse(state, merchantApiResponse): void {
        state.data = copyFromApiResponse(merchantApiResponse, state.data);
    },
    ...countrySpecificBusinessStructure.mutations
};

export const businessStructure = {
    namespaced: true,
    state:businessStructureDefaultState,
    getters,
    actions,
    mutations
};